import useConnectedProductApps from "@/apps/util/hooks/useConnectedProductApps"
import CurriculumDashboardBlockCardView from "@/dashboard/blocks/kinds/CurriculumDashboardBlockCardView"
import CurriculumDashboardBlockListView from "@/dashboard/blocks/kinds/CurriculumDashboardBlockListView"
import { CurriculumDashboardBlockFragment$key } from "@/dashboard/blocks/kinds/__generated__/CurriculumDashboardBlockFragment.graphql"
import { graphql, useFragment } from "react-relay"

interface Props {
  dashboardBlockKey: CurriculumDashboardBlockFragment$key
  index?: number
}

export default function CurriculumDashboardBlock({ dashboardBlockKey, index }: Props) {
  const block = useFragment<CurriculumDashboardBlockFragment$key>(
    graphql`
      fragment CurriculumDashboardBlockFragment on CurriculumDashboardBlock {
        curriculumView: view
        ...CurriculumDashboardBlockCardViewFragment
        ...CurriculumDashboardBlockListViewFragment
      }
    `,
    dashboardBlockKey
  )
  const { connectedApps } = useConnectedProductApps()

  // Don't show the block if there's no longer a curriculum app
  if (!connectedApps.has("curriculum")) return null

  return block.curriculumView === "list" ? (
    <CurriculumDashboardBlockListView dashboardBlockKey={block} index={index} />
  ) : (
    <CurriculumDashboardBlockCardView dashboardBlockKey={block} index={index} />
  )
}
