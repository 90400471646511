import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import {
  DashboardBlockPosition,
  RichTextDashboardBlockFragment$key,
} from "@/dashboard/blocks/kinds/__generated__/RichTextDashboardBlockFragment.graphql"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import DiscoEditor from "@components/editor/DiscoEditor"
import EditorUtils from "@components/editor/EditorUtils"
import { DiscoButton, DiscoButtonProps, DiscoSection, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import { absolutizeURL } from "@utils/string/stringUtils"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"

interface Props {
  dashboardBlockKey: RichTextDashboardBlockFragment$key
  index?: number
}

function RichTextDashboardBlock(props: Props) {
  const { dashboardBlockKey, index } = props

  const theme = useTheme()
  const dashboard = useDashboardContext()

  const block = useFragment<RichTextDashboardBlockFragment$key>(
    graphql`
      fragment RichTextDashboardBlockFragment on RichTextDashboardBlock {
        position
        richTextBlockTitle: title
        richEditorContent
        hasCtaButton
        ctaUrl
        ctaText
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
      }
    `,
    dashboardBlockKey
  )

  const classes = useStyles({
    position: block.position,
    hideHeaderTitle: !dashboard?.canEdit && !block.richTextBlockTitle,
    isCommunityWelcome: Boolean(dashboard?.isCommunityWelcome),
  })

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      <DiscoSection
        data-testid={"RichTextDashboardBlock.container"}
        className={classes.container}
        groovyDepths={"xs"}
        border
      >
        <div className={classes.headerTitleContainer}>
          {block.richTextBlockTitle && (
            <DiscoText
              variant={"body-lg-600"}
              testid={"RichTextDashboardBlock.title"}
              className={classes.title}
            >
              {block.richTextBlockTitle}
            </DiscoText>
          )}

          <DashboardBlockAdminDropdown dashboardBlockKey={block} />
        </div>
        {block.richEditorContent && !EditorUtils.isEmpty(block.richEditorContent) && (
          <DiscoEditor
            key={block.richEditorContent}
            testid={"RichTextDashboardBlock.description"}
            textColor={theme.palette.text.secondary}
            defaultValue={block.richEditorContent}
            readOnly
            disableGutter
            className={classes.richEditor}
          />
        )}
        {block.hasCtaButton && (
          <div className={classes.buttonContainer}>
            <DiscoButton {...computeCtaUrl()} className={classes.button}>
              {block.ctaText}
            </DiscoButton>
          </div>
        )}
      </DiscoSection>
    </DashboardBlockItemTemplate>
  )
  function computeCtaUrl(): DiscoButtonProps {
    if (!block.ctaUrl) return {}
    try {
      const urlWithProtocol = absolutizeURL(block.ctaUrl)
      const url = new URL(urlWithProtocol)
      const communityPath = url.origin === location.origin
      // if we're referring user to another page within community, use react-router to navigate
      return communityPath
        ? {
            to: {
              pathname: url.pathname,
              search: url.search,
            },
          }
        : { href: url.href, target: "_blank", rel: "noopener noreferrer" }
    } catch (error) {
      console.error(error)
      return {}
    }
  }
}

type StyleProps = {
  position: DashboardBlockPosition
  hideHeaderTitle: boolean
  isCommunityWelcome: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ isCommunityWelcome }: StyleProps) => ({
    width: "100%",
    overflowWrap: "anywhere",
    "& >:not(:last-child)": {
      marginBottom: theme.spacing(2.5),
    },
    ...(isCommunityWelcome && {
      paddingLeft: 0,
      paddingRight: 0,
    }),
  }),
  headerTitleContainer: (props: StyleProps) => ({
    display: props.hideHeaderTitle ? "none" : "flex",
    width: "100%",
    justifyContent: "flex-end",
  }),
  title: {
    width: "100%",
  },
  buttonContainer: (props: StyleProps) =>
    props.position === "main"
      ? {
          display: "flex",
          justifyContent: "flex-end",
          [theme.breakpoints.down("sm")]: {
            alignItems: "center",
          },
        }
      : {},
  button: (props: StyleProps) =>
    props.position === "main"
      ? {
          cursor: "pointer",
          [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
          maxWidth: "100%",
        }
      : {
          cursor: "pointer",
          width: "100%",
        },
  richEditor: {
    width: "100%",
    height: "unset",
  },
}))

export default RichTextDashboardBlock
