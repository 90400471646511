import { ActiveAppModalProvider } from "@/apps/util/activeAppModalContext"
import PostCard, { PostCardSkeleton } from "@/content/post/card/PostCard"
import { useLabel } from "@/core/context/LabelsContext"
import AddWithCircleIcon from "@/core/ui/iconsax/linear/add-circle.svg"
import DashboardBlockAdminDropdown from "@/dashboard/blocks/DashboardBlockAdminDropdown"
import DashboardBlockItemTemplate from "@/dashboard/blocks/kinds/DashboardBlockItemTemplate"
import { FeedDashboardBlockListViewFragment$key } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockListViewFragment.graphql"
import { FeedDashboardBlockListViewQuery } from "@/dashboard/blocks/kinds/__generated__/FeedDashboardBlockListViewQuery.graphql"
import { useDashboardEvent } from "@/dashboard/context/DashboardEventProvider"
import { useDashboardContext } from "@/dashboard/util/DashboardContext"
import CreatePostButton from "@/post/add/CreatePostButton"
import CreatePostInput from "@/post/add/CreatePostInput"
import AIFeedEmptyState from "@/post/list/empty-state/AIFeedEmptyState"
import CreatePostWithAIButton from "@/post/list/empty-state/CreatePostWithAIButton"
import OrganizationFeedPostsEmptyState from "@/post/list/empty-state/OrganizationFeedPostsEmptyState"
import ProductFeedPostsEmptyState from "@/post/list/empty-state/ProductFeedPostsEmptyState"
import useIsWebView from "@/product/util/hook/useIsWebView"
import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import AIBanner from "@components/ai/AIBanner"
import useCanTriggerAIPostSuggestion from "@components/ai/hooks/useCanTriggerAIPostSuggestion"
import { DiscoButton, DiscoEmptyState, DiscoInputSkeleton } from "@disco-ui"
import DiscoScrolledIntoView from "@disco-ui/scrolled-into-view/DiscoScrolledIntoView"
import { Grid } from "@material-ui/core"
import usePermissions from "@utils/hook/usePermissions"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useCallback, useEffect, useState } from "react"
import { graphql, useFragment } from "react-relay"
import { Virtuoso } from "react-virtuoso"

const POSTS_PER_PAGE = 5

interface Props extends TestIDProps {
  dashboardBlockKey: FeedDashboardBlockListViewFragment$key
  index?: number
}

function FeedDashboardBlockListView(props: Props) {
  const { dashboardBlockKey, index, testid = "FeedDashboardBlockListView" } = props
  const classes = useStyles()
  const label = useLabel("admin_experience")
  const memberLabel = useLabel("organization_member")
  const dashboardEvent = useDashboardEvent()
  const { canEdit, isOneColumn } = useDashboardContext()!

  const isWebView = useIsWebView()

  const block = useFragment<FeedDashboardBlockListViewFragment$key>(
    graphql`
      fragment FeedDashboardBlockListViewFragment on FeedDashboardBlock {
        id
        filter
        ...DashboardBlockAdminDropdownFragment
        ...DashboardBlockItemTemplateFragment
        product {
          viewerCanPostInFeeds
        }
        dashboard {
          id
          ...PostCardDashboardFragment
          ...usePermissionsFragment
          app {
            id
            feed {
              id
              isInactive
              ...AIFeedEmptyStateFragment
              ...usePermissionsFragment
            }
          }
        }
        organization {
          viewerCanPostInFeeds
        }
      }
    `,
    dashboardBlockKey
  )

  const feedId = block.dashboard.app?.feed?.id || ""
  const isFeedDashboard = Boolean(feedId)
  const { app } = block.dashboard
  const feedPermissions = usePermissions(app?.feed)

  const { data, pagination } =
    Relay.useRefetchablePaginationQuery<FeedDashboardBlockListViewQuery>(
      graphql`
        query FeedDashboardBlockListViewQuery(
          $blockId: ID!
          $dashboardId: ID!
          $first: Int
          $after: String
        ) {
          feedBlock(blockId: $blockId) {
            posts(first: $first, after: $after)
              @connection(key: "FeedDashboardBlockListView__posts") {
              __id
              totalCount
              edges {
                node {
                  id
                  ...PostCardFragment @arguments(dashboardId: $dashboardId)
                }
              }
            }
          }
        }
      `,
      {
        blockId: block.id,
        dashboardId: block.dashboard.id,
        first: POSTS_PER_PAGE,
      },
      {
        connectionName: "feedBlock.posts",
      }
    )

  const canCreatePosts = app?.feed
    ? feedPermissions.has("posts.create")
    : block?.product
    ? block?.product?.viewerCanPostInFeeds
    : block?.organization.viewerCanPostInFeeds

  useEffect(() => {
    if (!dashboardEvent) return
    // Add an event listener to refetch feed when a post is unpinned
    const unsubscribe = dashboardEvent.register("unpinned_post", refetchFeed)
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardEvent])

  useEffect(() => {
    if (!dashboardEvent) return
    // Add an event listener to refetch feed in list view when feed refetched in a card view
    const unsubscribe = dashboardEvent.register("refetch_feed_card_view", refetchFeed)
    return unsubscribe
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardEvent])

  const canTriggerAiSuggestionForEntity = useCanTriggerAIPostSuggestion({
    feedId,
  })

  const handleUnpinPost = useCallback(() => {
    if (isFeedDashboard) {
      // Dispatch the unpinned post event to update the pinned posts block
      dashboardEvent.dispatchEvent("unpinned_post")
      return
    }
    refetchFeed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeedDashboard])

  const handlePinPost = useCallback(() => {
    if (isFeedDashboard) {
      // Dispatch the pinned post event to update the pinned posts block
      dashboardEvent.dispatchEvent("pinned_post")
      return
    }
    refetchFeed()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFeedDashboard])

  const [el, setEl] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setEl(document.querySelector<HTMLElement>("#app-page-layout-scroll-container"))
  }, [])

  if (!data) return null

  const posts = Relay.connectionToArray(data?.feedBlock?.posts)
  const showCreatePostInput =
    (canEdit || canCreatePosts) && (!isFeedDashboard || !isOneColumn)

  if (!el) return null

  return (
    <DashboardBlockItemTemplate dashboardBlockKey={block} index={index}>
      {posts.length > 0 &&
        canCreatePosts &&
        app?.feed?.isInactive &&
        canTriggerAiSuggestionForEntity && (
          <AIBanner
            testid={"FeedDashboardBlockListView.create-post-with-ai-banner"}
            message={"Keep posting to boost engagement!"}
            buttons={
              <CreatePostWithAIButton feedId={app.feed.id} aiTemplate={"inactive"} />
            }
          />
        )}
      {/** only show this section if the user can at least edit the block or create posts  */}
      {showCreatePostInput && (
        <CreatePostInput
          feedId={app?.feed?.id}
          refetch={refetchFeed}
          tooltip={canCreatePosts ? undefined : "You do not have permission to post"}
          buttons={<DashboardBlockAdminDropdown dashboardBlockKey={block} />}
        />
      )}
      {posts.length ? (
        // For feed app dashboard, show the infinite scroll view
        feedId ? (
          <Virtuoso
            data={posts}
            totalCount={posts.length}
            customScrollParent={el}
            itemContent={(postIndex, post) => {
              return (
                <PostCard
                  className={classes.virtualizedPost}
                  testid={`${testid}-${postIndex}`}
                  postKey={post}
                  dashboardKey={block.dashboard}
                  onPin={handlePinPost}
                  onUnpin={handleUnpinPost}
                  isDashboardBlock
                  groovyDepths={"xs"}
                  hideHeaderPinButton
                  // Only clear notifications when we are on the feed app dashboard
                  clearNotifications={Boolean(feedId)}
                />
              )
            }}
            components={{
              Footer: () => {
                if (!pagination.hasNext) return null

                return (
                  <DiscoScrolledIntoView
                    className={classNames({
                      [classes.webViewScrolledIntoView]: isWebView,
                    })}
                    isLoading={pagination.isLoading}
                    onScrolledIntoView={() => pagination.loadMore({})}
                  />
                )
              },
            }}
          />
        ) : (
          <>
            <Grid container spacing={2}>
              {posts.map((post, i) => (
                <Grid key={post.id} item xs={12}>
                  <PostCard
                    testid={`${testid}-${i}`}
                    postKey={post}
                    dashboardKey={block.dashboard}
                    onPin={handlePinPost}
                    onUnpin={handleUnpinPost}
                    hideHeaderPinButton
                    groovyDepths={"xs"}
                    isDashboardBlock
                    // Only clear notifications when we are on the feed app dashboard
                    clearNotifications={isFeedDashboard}
                  />
                </Grid>
              ))}
            </Grid>
            {pagination.hasNext &&
              !pagination.isLoading && ( // Let user mannually load
                <div className={classes.footer}>
                  <DiscoButton
                    color={"grey"}
                    variant={"outlined"}
                    onClick={() => pagination.loadMore({})}
                    size={"large"}
                    className={classes.footerButton}
                  >
                    {"Show More"}
                  </DiscoButton>
                </div>
              )}
            {pagination.isLoading && (
              <div className={classes.paginationSkeleton}>
                <PostCardSkeleton />
                <PostCardSkeleton />
                <PostCardSkeleton />
                <PostCardSkeleton />
                <PostCardSkeleton />
              </div>
            )}
          </>
        )
      ) : canCreatePosts ? (
        canTriggerAiSuggestionForEntity ? (
          <ActiveAppModalProvider>
            <AIFeedEmptyState feedKey={app!.feed!} />
          </ActiveAppModalProvider>
        ) : (
          <CreatePostButton feedId={feedId} refetch={refetchFeed}>
            {(buttonProps) => {
              return (
                <DiscoEmptyState
                  className={classes.emptyState}
                  testid={testid}
                  icon={<AddWithCircleIcon width={40} height={40} />}
                  title={"Add First Post"}
                  subtitle={
                    canEdit
                      ? isFeedDashboard
                        ? `Posts are a way of communicating with your entire Cohort and drive them back to the ${label.singular} to create engagement. For example, you can remind them of an upcoming event.`
                        : block.product
                        ? `Create your first post to feature it here on your ${label.singular} dashboard.`
                        : `This feed will show ${memberLabel.plural} all posts they have access to across the Community and from ${label.plural}`
                      : `Posts are a way of communicating with your entire Cohort.`
                  }
                  onClick={buttonProps.onClick as VoidFunction}
                />
              )
            }}
          </CreatePostButton>
        )
      ) : isFeedDashboard ? (
        getFeedEmptyState()
      ) : (
        <DiscoEmptyState
          className={classes.emptyState}
          testid={testid}
          title={`Meet Your ${label.singular} Dashboard Feed!`}
          subtitle={
            "Your feed will start being populated with relevant content as content becomes available."
          }
        />
      )}
    </DashboardBlockItemTemplate>
  )

  function refetchFeed() {
    pagination.refetch({})
  }

  function getFeedEmptyState() {
    if (!feedId) return null

    if (block?.product) {
      return <ProductFeedPostsEmptyState canCreatePosts={canCreatePosts} />
    }

    return <OrganizationFeedPostsEmptyState canCreatePosts={canCreatePosts} />
  }
}

const useStyles = makeUseStyles((theme) => ({
  emptyState: {
    height: "346px",
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.xl,
    [theme.breakpoints.down("xs")]: {
      padding: 0,
    },
    color: theme.palette.text.primary,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
  },
  footerButton: {
    width: "100%",
  },
  skeleton: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  virtualizedPost: {
    marginBottom: theme.spacing(2),
  },
  webViewScrolledIntoView: {
    // Add extra padding for the webview to help ensure
    // the scrolled into view component is visible
    paddingBottom: theme.spacing(3),
  },
  paginationSkeleton: {
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}))

function FeedDashboardBlockListViewSkeleton() {
  const classes = useStyles()
  return (
    <div className={classes.skeleton}>
      <DiscoInputSkeleton height={90} />
      <PostCardSkeleton />
      <PostCardSkeleton />
    </div>
  )
}

export default Relay.withSkeleton({
  component: FeedDashboardBlockListView,
  skeleton: FeedDashboardBlockListViewSkeleton,
})
